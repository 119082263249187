export default {
  heading: 'Thasadith, sans-serif',
  subheading: 'Noto Sans Display',
  body: 'Comfortaa, sans-serif',
  body2: 'Josefin Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: '',
  googleFonts: [
    'Playfair Display:400,500,600,700',
    'Comfortaa:100,200,300,400,500,600,700,800,900',
    'Noto Sans Display:100,200,300,400,500,600,700,800,900',
    'Merriweather:100,200,300,400,500,600,700,800,900',
    'Josefin Sans:100,200,300,400,500,600,700,800,900',
    'Thasadith:400,700'
  ]
  // customFamilies: ['Burford-Rustic'],
  // customUrls: [
  //   'https://www.gonation.biz/fonts/burford-rustic/burford-rustic.css',
  // ],
}
