export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // ? Container

    '.container': {
      padding: '0.5rem 1rem',
      position: 'absolute',
      backgroundColor: 'transparent',
      top: '0rem',

      '.smallNavMenu': {
        '.react-reveal': {
          opacity: '1'
        }
      },
      '> .phoneContainer': {
        display: 'none'
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 1rem',
      backgroundColor: 'white',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      '.hamburger': {
        height: '45px',
        width: '45px',
        backgroundColor: 'white',
        '>div': {
          height: '2px'
        }
      },
      '> .phoneContainer': {
        display: 'none'
      }
    },

    // ? Logo

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem',
      padding: '0rem'
    },
    '.logo': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['70px', '90px', '120px', ''],
        // filter: 'brightness(0) invert(1)',
        padding: '0rem',
        filter: 'unset'
        // maxWidth: ['', '', 'unset', 'unset'],
      }
    },
    '.logoScrolled': {
      // display: 'none',
      img: {
        padding: '0rem',
        maxHeight: ['40px', '60px', '60px', '60px']
      }
    },

    // ? Small Nav Menu

    '.smallNavMenu': {
      '> div': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      '.navItem': {
        a: {
          color: 'primary'
        }
      },
      'li.get-a-quote': {
        variant: 'buttons.navButton',
        margin: '0rem 0.5rem',
        ':hover': {
          backgroundColor: 'secondary'
        },
        a: {
          color: 'white',
          overflow: 'hidden',
          p: '0.5rem 2rem'
        }
      }
    },

    // ? hamburger

    '.hamburger': {
      // borderRadius: '100px',
      padding: '0.75rem',
      margin: '0rem 0rem 0rem 1rem',
      borderColor: 'primary',
      // backgroundColor: 'secondary',
      '> div': {
        // backgroundColor: 'primary',
      }
    },
    '.hamburgerOpen': {
      // borderRadius: '100px',
      margin: ['', '', '', '0rem 0rem 0rem 1rem'],
      padding: '0.75rem',
      borderColor: 'primary',
      '> div': {
        // backgroundColor: 'primary',
        width: '75%'
      }
    },

    // ? Nav Menu

    '.navMenuLogo': {
      // display: 'none',
      mb: '2rem',
      img: {
        maxHeight: '200px'
      }
    },

    '.navMenu': {
      transform: 'unset',
      right: '0rem',
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'background'
    },

    '.navMenuOpen': {
      alignItems: ['flex-end'],
      padding: ['3rem 1rem 2rem', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'background',

      '.navItemDropdown': {
        width: '100%',
        textAlign: 'right',
        '.navItemDropdownMenu': {
          background: 'none',
          borderTop: 'solid 1px grey',
          borderBottom: 'solid 1px grey',
          position: 'static',
          '.navItem': {
            a: {
              fontSize: ['0.85rem', '1rem', '1.1rem', '1.2rem', '1.2rem']
            }
          }
        }
      },

      '.navItem': {
        textAlign: 'right',
        justifyContent: 'flex-end',
        whiteSpace: 'normal',
        textShadow: 'none',
        a: {
          fontSize: ['1rem', '1.2rem', '1.3rem', '1.4rem', '1.5rem'],
          color: 'primary',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0rem 1rem auto'],
        width: '100%',
        maxWidth: 'unset',
        backgroundColor: 'primary',
        borderColor: 'primary',
        borderWidth: '0.5px',
        height: '0.5px',
        order: '11'
      },
      '.phoneSocialContainer': {
        order: '12',

        flexDirection: ['column', '', '', 'column'],
        alignItems: ['flex-end'],
        textAlign: ['right'],
        width: ['100%', '100%', '100%', '100%'],
        '.phoneContainer': {
          width: '100%'
        },
        a: {
          textAlign: ['right'],
          justifyContent: ['flex-end'],
          fontSize: '1rem',
          margin: '0rem 0rem 0.25rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['right'],
        color: 'secondary'
      },

      '.socialIconsContainer': {
        alignItems: ['flex-end'],
        textAlign: ['right'],
        width: ['100%', '100%', '100%', '100%'],
        justifyContent: 'flex-end',
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['right'],
          justifyContent: ['flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'primary'
            }
          }
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh',
      opacity: '0'
    },
    '.navBlockOpen': {
      // backgroundColor: 'transparent',
    },

    // ?  widgets

    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        color: 'primary',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    }
  },

  footer: {
    backgroundColor: 'transparent',
    borderTop: 'solid 2px',
    borderColor: 'primary',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'text',
    padding: ['0.5rem 0.5rem 6rem', '', '2rem 3rem'],
    '.image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        color: 'text'
      },
      '.contact-us': { order: '20' }
    },
    '.contactDetails-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      color: 'text',
      flexWrap: 'wrap',
      alignItems: 'center'
    },
    '.copyright': {
      width: '50%',
      color: 'text',

      textAlign: 'center'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1rem',
      a: {
        svg: {
          width: '40px',
          height: '40px',
          padding: '0.5rem',
          border: 'solid 1px black',
          borderRadius: '100px',
          color: 'text',
          fill: 'text',
          path: {
            fill: 'text'
          },
          ':hover': {
            backgroundColor: 'grey'
          }
        },
        margin: '0.5rem'
      }
    },
    '.gonationLogo': {
      width: '50%',
      filter: 'unset'
      // '.poweredByText': {
      //   color: 'text',
      //   svg: {
      //     path: {
      //       fill: 'text',
      //     },
      //   },
      // },
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    textAlign: 'inherit',
    alignItems: 'inherit',
    marginBottom: '1.5rem',
    border: 'none',
    textTransform: 'lowercase',
    letterSpacing: ['0.5rem', '0.75rem', '', '1rem'],
    fontSize: ['2rem', '2.25rem', '2.75rem', '3rem', '3.5rem'],
    fontWeight: '300',
    fontFamily: 'heading',
    color: '#433a3a',
    order: '1'
  },
  subtitle: {
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.5rem', '2.75rem'],
    fontWeight: '300',
    alignItems: 'inherit',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    fontFamily: 'body',
    color: 'primary',
    border: 'none',
    order: '2'
  },
  text: {
    textAlign: 'inherit',
    marginBottom: '0.5rem',
    color: 'gray',
    order: '3',
    mb: '2rem',
    p: {
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'inherit',
      color: 'gray',
      fontWeight: '300'
    }
  },

  sideBySide1: {
    padding: ['1rem', '', '2rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // sideBySide2: {
  //   variant: 'customVariants.sideBySide1',
  // },

  titleBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: ['1.5rem', '2rem', '3rem', '5rem 5rem 3rem'],
    maxWidth: '1200px',
    textAlign: 'left',
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '4rem', '5rem'],
      margin: '0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    // marginTop: '150px',
    height: 'unset',
    maxHeight: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '.hero_content_container': {
      position: 'static',
      order: '99',
      // top: '8%',
      // left: '50%',
      transform: 'unset',
      color: 'unset',
      padding: ['1rem', '', '2rem'],
      alignItems: 'center',
      justifyContent: 'center',
      width: 'fit-content',
      maxWidth: '1000px',
      margin: ' 0rem auto',
      textAlign: 'center',
      marginBottom: '3rem'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3.5rem', '4rem', '4.5rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      marginBottom: '2rem'
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: ['1.25rem', '', '', '1.4rem'],
      p: {
        color: 'grey',
        fontSize: ['1.25rem', '', '', '1.5rem'],
        lineHeight: ['1.5', '1.75', '', '1.75'],
        fontWeight: '300'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '.slick-initialized': {
      order: '2',

      width: '100%',
      height: 'auto',
      '.slick-slide': {
        height: ['50vh', '', '', '60vh'],
        '> div': {
          height: '60vh',
          img: {
            height: '60vh'
          }
        }
      },

      '.slick-arrow': {
        left: 'unset',
        right: '1rem',
        top: '2rem',
        bottom: 'unset',
        backgroundColor: 'white',
        border: 'solid 1px',
        borderColor: 'primary',
        width: '40px',
        height: '40px'
      },
      '.slick-prev': {
        right: '4rem'
      }
    }
  },

  homepageHeroShout: {
    display: 'none'
  },

  homepagePolicy: {
    // variant: 'customVariants.sideBySide1',
    // display: 'none',
    mx: 6,
    pb: 8,
    '.es-content-section': {
      height: 'auto!important',
      bg: 'orange'
    },
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary',
      color: 'dark',
      fontSize: ['2rem', '', '2rem']
    },
    '.text': {
      lineHeight: 1.75,
      color: 'text',
      textTransform: 'none'
    },
    '.innerContainer': {
      height: 'auto'
    }
  },

  homepageVenues: {
    variant: 'customVariants.sideBySide1',
    padding: ['2rem', '3rem', '4rem', '5rem'],
    // maxWidth: '1300px',
    margin: '0rem auto',
    overflow: 'hidden',
    'div.lazyload-wrapper': {
      position: 'relative',
      margin: '5%',
      height: ['100vw', '90vw', '80vw'],
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        paddingBottom: '100%'
      },
      img: {
        zIndex: '5',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%'
      },
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        backgroundColor: '#d1cdc0',
        position: 'absolute',
        right: '-1rem',
        top: '-1rem',
        zIndex: '0'
      }
    },
    'div.lazyload-wrapper': {
      position: 'relative',
      margin: '5%',
      height: ['100vw', '60vw', '60vw'],
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        paddingBottom: '100%'
      },
      img: {
        zIndex: '5',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        height: '100%'
      },
      '::after': {
        width: '100%',
        height: '100%',
        content: "''",
        backgroundColor: '#d1cdc0',
        position: 'absolute',
        left: '-1rem',
        top: '-1rem',
        zIndex: '0'
      }
    }
  },

  homepageBoxes1: {
    // backgroundColor: '#efefef',
    padding: ['0rem', '0rem', '0rem', '0rem'],
    mb: '3rem',
    '.contentBoxes > div:nth-of-type(odd)': {
      alignItems: ['flex-start', '', 'flex-end'],
      textAlign: ['left', '', 'right'],
      'div.text': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: ['flex-start', '', 'flex-end']
      }
    },
    '.contentBoxes > div:nth-of-type(even)': {
      alignItems: 'flex-start',
      textAlign: 'left'
    },

    '.box': {
      // height: '70vh',
      width: ['100%', '', 'calc(50% - 4rem)', 'calc(33.3% - 6rem)'],
      margin: ['', '', '2rem', '3rem'],
      border: 'solid 1px',
      borderColor: 'background',
      transition: 'all ease-in-out 1s',
      borderRadius: '0px',
      flexGrow: '1',
      position: 'relative',
      display: 'flex',
      justifyContent: ['center', 'center', 'center', 'center'],
      flexDirection: 'column',
      overflow: 'visible',
      backgroundColor: 'secondary',
      minHeight: '60vh',
      padding: ['1rem', '2rem', '3rem', '4rem'],
      '.image': {
        height: ['auto', '', '450px'],
        objectFit: 'cover',
        transition: 'all ease-in-out 1s',
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '0',
        objectFit: 'cover',
        display: 'none'
      },
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.5rem', '1.75rem', '2rem', '2.25rem'],
        transition: 'all ease-in-out 1s',
        padding: '1rem',
        zIndex: '1',
        margin: '0rem'
        // width: ['90%', '70%', '70%', '85%', '70%'],
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        padding: '1rem',
        zIndex: '1',
        margin: '0rem',
        color: '#b8933d',
        // width: ['90%', '70%', '70%', '85%', '70%'],
        fontSize: ['1.25rem', '1.25rem', '1.5rem', '1.75rem', '2rem']
      },
      '.text': {
        variant: 'customVariants.text',
        opacity: '0.5',
        zIndex: '1',
        padding: '1rem',
        margin: '0rem 0rem 2rem',
        p: {
          maxWidth: '400px'
        }
      },
      a: {
        padding: '1rem',
        margin: '0rem'
      },
      '.ctaLink': {
        variant: 'buttons.secondary',
        color: '#734a39',
        backgroundColor: 'transparent',
        borderColor: '#734a39',
        order: '4',
        zIndex: '1',
        ':hover': {
          color: '#202020',
          backgroundColor: 'primary',
          borderColor: 'primary'
        }
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      margin: '0rem 0rem 1rem'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.imageContainer': {
      '.image1': {
        height: '60%',
        margin: 'auto',
        position: 'relative',
        right: ['-2rem', '', '-3rem'],
        backgroundColor: '#f9f9f9',
        border: 'solid 9px',
        borderColor: 'primary'
      },
      '.image2': {
        border: 'solid 9px',
        borderColor: 'secondary',
        marginBottom: '0.5rem'
      },
      '.image3': {
        border: 'solid 9px',
        borderColor: 'grey'
      }
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    marginBottom: '3rem',
    padding: ['1rem', '', '', '0rem 0rem'],
    borderTop: '1px solid',
    borderBottom: '1px solid',
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: 'primary',
      fontFamily: 'subheading',
      width: ['', '', '80%'],
      color: 'white'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.75rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '0rem 2rem'],
      backgroundColor: ['transparent'],
      color: 'text'
    },
    '.date': {
      backgroundColor: 'secondary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: 'text',
      flexGrow: '1'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1'
    },

    margin: '0rem 0rem',
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', 'auto'],
      a: {
        width: ['50%', '', '', '100%'],
        writingMode: ['unset', 'unset', 'unset', 'tb'],
        padding: '1rem',
        backgroundColor: 'transparent',
        color: 'primary',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white'
        }
      }
    }
  },

  homepageSlider: {
    overflow: 'hidden',
    '.slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div, .slick-slide img': {
      width: '100%',
      height: '75vh',
      img: {
        objectFit: 'cover'
      }
    },
    '.slick-dots': {
      bottom: '40px',
      li: {
        button: {
          '::before': {
            color: '#327d8adb',
            fontSize: '1.5rem'
          }
        }
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {},

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '10vh 2rem',
    order: '2',
    backgroundColor: 'white',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    h3: {
      color: 'secondary'
    },
    'p.text': {
      color: 'text',
      maxWidth: '650px',
      margin: '2rem 0rem'
    },
    backgroundPosition: 'center center',
    color: 'dark',
    '.inputField, .textField ': {
      border: 'none',
      borderBottom: '2px solid',
      fontWeight: '300',
      borderColor: 'primary',
      fontFamily: 'body',

      borderWidth: '1px',
      borderRadius: '0px',
      padding: '0.5rem',
      color: 'text',
      '::placeholder': {
        color: 'text',
        fontFamily: 'body'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto',
      borderColor: 'primary',
      padding: '0.5rem 1rem'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center',
      letterSpacing: '4px'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  }
}
